import React from 'react'
import Group from "../assets/image/teams/Group.JPG";
import Group1 from "../assets/image/teams/Group1.JPG";
import Group2 from "../assets/image/teams/Group2.JPG";
const Collective = () => {
  return (
    <div
    class="flex items-center mt-6"
    // style="font-family: 'Muli', sans-serif;"
  >
    <div class="container ml-auto mr-auto flex flex-wrap items-start">
      <div class="w-full pl-5 lg:pl-2 mb-4 mt-4">
        <h1 class="text-3xl lg:text-4xl text-gray-700 font-extrabold">
          Komandamız
        </h1>
        
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
        <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
          <figure class="mb-2">
            <img src={Group} alt="" class="h-64 ml-auto mr-auto" />
          </figure>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
        <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
          <figure class="mb-2">
            <img src={Group1} alt="" class="h-64 ml-auto mr-auto" />
          </figure>
        </div>
      </div>

      <div class="w-full md:w-1/2 lg:w-1/3 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
        <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
          <figure class="mb-2">
            <img src={Group2} alt="" class="h-64 ml-auto mr-auto" />
          </figure>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Collective