import React from "react";
import Carousel from "../../components/Carousel";
import design from "../../assets/image/book/design.avif";
import designLog from "../../assets/image/book/designLog.avif";
import last from "../../assets/image/book/last.avif";
const Book = () => {
  return (
    <div>
      <div
        role="main"
        className="flex flex-col items-center justify-center mb-24"
      >
        <h1 className="text-4xl font-semibold leading-9 text-center text-black mt-64">
          Kitabımız
        </h1>
        <p className="text-lg leading-normal text-center text-black mt-4 lg:w-1/2 md:w-10/12 w-11/12">
          Kitablarımız və Proqramlarımız haqqında məlumat ala bilərsiniz.{" "}
        </p>
      </div>

      <Carousel />

      <section class="px-2 py-32 bg-white md:px-0 mt-32">
        <div class="container items-center max-w-6xl px-8 mx-auto xl:px-5">
          <div class="flex flex-wrap items-center sm:-mx-3">
            <div class="w-full md:w-1/2 md:px-3">
              <div class="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
                <h1 class="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
                  AFL Geyim Üzrə Konstruksiya Və Modelləmə Üsulları
                </h1>
                <p class="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
                  Bu kitabın əsas məqsədi tələbələrin öyrənmə prosesini
                  asanlaşdırmaqdır.Kitabdakı konstruksiya və modelləmə sistemi
                  Azərbaycana aid müəllif metodikasıdır.(Ruhiyyə Ə.)
                </p>
                <p>
                  AFL konstruksiya və modelləmə sistemində Azərbaycana xas insan
                  antropometriyası nəzərə alındığı üçün geyimlər bədənə qüsursuz
                  uyğunlaşır.Eyni zamanda bu kitab moda dizayneri,geyim
                  konstruktoru,modalyer və bu sahənin biznes sahiblərinə
                  istehsal prosesində istiqamət verəcək bir mənbədir. Günün
                  dəbinə uyğun dizayn edilən modellərin estetik görünməsi,
                  bədənə uyğunlaşması və hərəkət sabitliyini təmin etməsi ancaq
                  geyim qəliblərinin yaxşı işlənməsi ilə mümkündür.Parça
                  keyfiyyəti yüksək olsa belə deffektli qəliblə hazırlanmış
                  geyim məhsulunun satışı istənilən səviyyədən çox uzaqdır.Bu
                  çərçivədə hazır geyim istehsalında qəliblərin dəqiqliyi vacib
                  məsələlərdən birinə çevrilir. Konstruksiya,modelləmə və
                  serialama mərhələsini öyrədən bu kitab, təlabat və zövqə cavab
                  verən yüksək estetik və utilitar xüsusiyyətlərə malik geyim
                  məhsullarının yaradılması üçün nəzərdə tutulub.
                </p>

                <h5>
                  Müəlliflər: Ruhiyyə Əliyeva Nadir qızı, Pərvin Əyyubova Elman
                  qızı.
                </h5>
                <p>
                  <span className="font-bold">QEYD:</span> Bu kitabın təkrar və
                  hissə-hissə nəşri və çoxaldılması “Müəlliflik hüququ və
                  əlaqəli hüquqlar haqqında Azərbaycan Respublikasının Qanunu”na
                  ziddir.
                </p>
                <div class="relative flex flex-col sm:flex-row sm:space-x-4">
                  <a
                    href="#_"
                    class="flex items-center px-6 py-3 text-white bg-black rounded-md"
                  >
                    Daha çox
                  </a>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2">
              <div class="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl">
                <img src="https://images.unsplash.com/photo-1498049860654-af1a5c566876?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="w-full bg-white pt-7 pb-7 md:pt-20 md:pb-24">
        <div class="box-border flex flex-col items-center content-center px-8 mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:flex-row max-w-7xl lg:px-16">
          <div class="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10">
            <img src={designLog} class="p-2 pl-6 pr-5 xl:pl-16 xl:pr-20 " />
          </div>

          <div class="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none">
            <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
              PROQRAMLAR
            </h2>

            <p class="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-lg">
              Build an atmosphere that creates productivity in your organization
              and your company culture.
            </p>
            <ul class="p-0 m-0 leading-6 border-0 border-gray-300">
              <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                1-Moda Dizaynı və Texnologiyası
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                2-Moda Eskizi və Dizaynı
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                3-Rəqəmsal Moda Eskizi
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                4-Konstruktor Tikiş Texnoloqu
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                5-Konstruktor Təkmilləşmə
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                6-Klassik Geyimlər
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                7-Uşaq Geyimləri
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                8-İç Geyimlər
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                9-Korsetlər
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                10-Libaslar
              </li>
              <li class="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                <span class="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span class="text-sm font-bold">✓</span>
                </span>{" "}
                11-“Street-Style”
              </li>
            </ul>
          </div>
        </div>
        <div class="box-border flex flex-col items-center content-center px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-7xl lg:px-16">
          <div class="box-border w-full text-black border-solid md:w-1/2 md:pl-6 xl:pl-32 mt-16">
            <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
              Moda Dizaynı və Texnologiyası
            </h2>
            <p class="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-10 lg:text-lg ">
              Eskizi istehsal vəziyyətinə gətirə bilirsənsə sən artıq moda
              dizaynerisən! AFL-də dizayner olacaq tələbələr bu proqramda
              kolleksiyaların konstruksiyasını, modelləməsini və tikiş
              texnologiyasını öyrənirlər.Eynilə Kolleksiyaların
              tərkibi,inkişafı,idarə olunması və işin kommersiya tərəfi müzakirə
              olunur.Bu proqramdakı əsas məqsəd texniki eskizə əsaslanaraq
              kolleksiya yaratmaqdır.Onun axıcı və real olması üçün rənq
              spektrlarından, parçalardan, ornament, yaradıcı mulajdan istifadə
              olunur.Eyni zamanda kolleksiyanın rəqəmsal veriyasını yaratmaq
              üçün “Adobe” proqramları tədris olunur.Əlavə olaraq hər dizaynerin
              bilməli olduğu məqamlar, moda tarixi, texniki və yaradıcı eskiz,
              stillər, rəng nəzəriyyəsi, parçalar, printlər, kollajlar və
              “moodboard”da proqrama daxildir. Tələbə sonda sağlam “portfolio”
              əldə edir.
            </p>
          </div>

          <div class="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
            <img src={design} class="pl-4 sm:pr-10 xl:pl-10 lg:pr-32" />
          </div>
        </div>
      </section>

      <section class="py-20 bg-gray-50">
        <div class="container items-center max-w-6xl px-4 px-10 mx-auto sm:px-20 md:px-32 lg:px-16">
          <div class="flex flex-wrap items-center -mx-3">
            <div class="order-1 w-full px-3 lg:w-1/2 lg:order-0">
              <div class="w-full lg:max-w-md">
                <h2 class="mb-4 text-3xl font-bold leading-tight tracking-tight sm:text-4xl font-heading">
                  Qısa müddətli Proqramlar
                </h2>
                <p class="mb-4 font-medium tracking-tight text-gray-400 xl:mb-6">
                  Bu formada, yəni detallara bölərək təqdim olunan təhsil
                  maraqlı və faydalıdır. Dünyada moda təhsili təcrübəsində
                  tələbələrə geniş imkan yaradılır, istənilən proqramı seçim
                  etmək şansı verilir. Azerbaijan Fashion LAB da ölkədəki moda
                  sevərlərə bu imkanları yaradır.Təcrübəmizdən deyə bilərik ki,
                  gələn bir çox tələbə fərqli istiqamətdə öyrənmək iş qurmaq və
                  irəliləmək istəyir. Belə olduqda şablon proqramın yetərli
                  olması təbii ki mümkün deyil. Buna görə də istəyirsiniz
                  “Klassik Geyimlər”, istəyirsiniz öz dizaynınız olan
                  kolleksiyanız və ya baza geyimlərin ön sınamasız, standarta
                  uyğun hazırlanması, istəyirsiniz “Uşaq Geyimləri”,
                  ”Korsetlər”, ”Libaslar”, ”İç Geyimlər”, ”Street style”(kişi və
                  qadınların geyinə biləcəyi geyimlər)eyni zamanda da bütün
                  proqramın öyrədilməsini seçim edin. Bu proqramlarda tələbələr
                  sadalanan geyim növlərinin həm konstruksiyasını həm də tikiş
                  texnologiyasını peşəkar şəkildə öyrənirlər.
                </p>
                {/* <ul>
                  <li class="flex items-center py-2 space-x-4 xl:py-3">
                    <svg
                      class="w-8 h-8 text-pink-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
                      ></path>
                    </svg>
                    <span class="font-medium text-gray-500">
                      Faster Processing and Delivery
                    </span>
                  </li>
                  <li class="flex items-center py-2 space-x-4 xl:py-3">
                    <svg
                      class="w-8 h-8 text-yellow-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                      ></path>
                    </svg>
                    <span class="font-medium text-gray-500">
                      Out of the Box Tracking and Monitoring
                    </span>
                  </li>
                  <li class="flex items-center py-2 space-x-4 xl:py-3">
                    <svg
                      class="w-8 h-8 text-green-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                      ></path>
                    </svg>
                    <span class="font-medium text-gray-500">
                      100% Protection and Security for Your App
                    </span>
                  </li>
                </ul> */}
              </div>
            </div>
            <div class="w-full px-3 mb-12 lg:w-1/2 order-0 lg:order-1 lg:mb-0">
              <img
                class="mx-auto sm:max-w-sm lg:max-w-full"
                src={last}
                alt="feature image"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Book;
