import React from "react";
import Team from "../../components/Team";
import Statics from "../../components/Statics";
import Slider from "../../components/Slider";

import { MdOutlineDoubleArrow } from "react-icons/md";
import Collective from "../../components/Collective";
import SlideShow from "../../components/SlideShow";
const Home = () => {
  return (
    <div>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center mt-16"
        style={{ minHeight: "95vh" }}
      >
        <div class="absolute top-0 w-full h-full bg-top bg-cover home_page">
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto" data-aos="fade-in">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4 ml-auto mr-auto text-white">
              <div>
                <h4 class="text-5xl text-center mb-1 font-semibold mb-4">
                  Bizimlə tanış olmağa hazırsınız?
                </h4>

                <p class="text-xl">
                  Only 100 years ago did mankind think it would be possible for
                  humans to inhabit other planets. Many hurdles had to be
                  overcome such as resource and land management, societal and
                  business infrastructure, and most important: planet
                  terraforming for humans to even be able to survive. Once it
                  had become possible to reach Mars in a timely way the first
                  settlers could start making Mars a home for all. They began
                  the ardous journey of putting together what would eventually
                  become Elon City. It started out as many cities do - a small
                  town in an unknown place - and from there it became the
                  central hub of the red planet.
                </p>
                <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6 justify-center">
                  <button className="mt-4 px-8 py-2 text-white rounded-lg bg-black">
                    Başlayaq
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <SlideShow /> */}

      {/* <Slider /> */}
      <Collective />
      <Statics />
    </div>
  );
};

export default Home;
