import React from "react";
import { Link } from "react-router-dom";
import Group from "../../assets/image/courses/course1.JPG";
import Group1 from "../../assets/image/courses/course2.JPG";
import Group2 from "../../assets/image/courses/courseThird.JPG";
import Group3 from "../../assets/image/courses/courseFourth.JPG";
import "../../assets/scss/card.scss";
const Courses = () => {
  return (
    // <div className="flex justify-center items-center mt-32">
    //   <div className="2xl:mx-auto 2xl:container lg:px-20 lg:py-16 md:py-12 md:px-6 py-9 px-4 w-96 sm:w-auto">
    //     <div role="main" className="flex flex-col items-center justify-center">
    //       <h1 className="text-4xl font-semibold leading-9 text-center text-black">
    //       Kurslarımız
    //       </h1>
    //       <p className="text-base leading-normal text-center text-black mt-4 lg:w-1/2 md:w-10/12 w-11/12">
    //         Ətraflı düyməsinə basaraq daha geniş məlumat əldə edə bilərsiniz.
    //       </p>
    //     </div>
    //     <div className="lg:flex items-stretch md:mt-12 mt-8">
    //       <div className="lg:w-1/2">
    //         <div className="sm:flex items-center justify-between xl:gap-x-8 gap-x-6">
    //           <div className="sm:w-1/2 relative">
    //             <div>
    //               <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">
    //                 12 April 2021
    //               </p>
    //               <div className="absolute bottom-0 left-0 p-6">
    //                 <h2 className="text-xl font-semibold 5 text-white">
    //                   The Decorated Ways
    //                 </h2>
    //                 <p className="text-base leading-4 text-white mt-2">
    //                   Dive into minimalism
    //                 </p>
    //                 <Link
    //                   to="/courses/1"
    //                   className="focus:outline-none focus:underline flex items-center mt-4 cursor-pointer text-white hover:text-gray-200 hover:underline"
    //                 >
    //                   <p className="pr-2 text-sm font-medium leading-none">
    //                     Read More
    //                   </p>
    //                   <svg
    //                     className="fill-stroke"
    //                     width="16"
    //                     height="16"
    //                     viewBox="0 0 16 16"
    //                     fill="none"
    //                     xmlns="http://www.w3.org/2000/svg"
    //                   >
    //                     <path
    //                       d="M5.75 12.5L10.25 8L5.75 3.5"
    //                       stroke="currentColor"
    //                       stroke-width="2"
    //                       stroke-linecap="round"
    //                       stroke-linejoin="round"
    //                     />
    //                   </svg>
    //                 </Link>
    //               </div>
    //             </div>
    //             <img src={Group1} className="w-full" alt="chair" />
    //           </div>
    //           <div className="sm:w-1/2 sm:mt-0 mt-4 relative">
    //             <div>
    //               <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">
    //                 12 April 2021
    //               </p>
    //               <div className="absolute bottom-0 left-0 p-6">
    //                 <h2 className="text-xl font-semibold 5 text-white">
    //                   The Decorated Ways
    //                 </h2>
    //                 <p className="text-base leading-4 text-white mt-2">
    //                   Dive into minimalism
    //                 </p>
    //                 <Link
    //                   to="/blog/2"
    //                   className="focus:outline-none focus:underline flex items-center mt-4 cursor-pointer text-white hover:text-gray-200 hover:underline"
    //                 >
    //                   <p className="pr-2 text-sm font-medium leading-none">
    //                     Read More
    //                   </p>
    //                   <svg
    //                     className="fill-stroke"
    //                     width="16"
    //                     height="16"
    //                     viewBox="0 0 16 16"
    //                     fill="none"
    //                     xmlns="http://www.w3.org/2000/svg"
    //                   >
    //                     <path
    //                       d="M5.75 12.5L10.25 8L5.75 3.5"
    //                       stroke="currentColor"
    //                       stroke-width="2"
    //                       stroke-linecap="round"
    //                       stroke-linejoin="round"
    //                     />
    //                   </svg>
    //                 </Link>
    //               </div>
    //             </div>
    //             <img src={Group2} className="w-full" alt="wall design" />
    //           </div>
    //         </div>
    //         <div className="relative">
    //           <div>
    //             <p className="md:p-10 p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">
    //               12 April 2021
    //             </p>
    //             <div className="absolute bottom-0 left-0 md:p-10 p-6">
    //               <h2 className="text-xl font-semibold 5 text-white">
    //                 The Decorated Ways
    //               </h2>
    //               <p className="text-base leading-4 text-white mt-2">
    //                 Dive into minimalism
    //               </p>
    //               <Link
    //                 to="/blog/3"
    //                 className="focus:outline-none focus:underline flex items-center mt-4 cursor-pointer text-white hover:text-gray-200 hover:underline"
    //               >
    //                 <p className="pr-2 text-sm font-medium leading-none">
    //                   Read More
    //                 </p>
    //                 <svg
    //                   className="fill-stroke"
    //                   width="16"
    //                   height="16"
    //                   viewBox="0 0 16 16"
    //                   fill="none"
    //                   xmlns="http://www.w3.org/2000/svg"
    //                 >
    //                   <path
    //                     d="M5.75 12.5L10.25 8L5.75 3.5"
    //                     stroke="currentColor"
    //                     stroke-width="2"
    //                     stroke-linecap="round"
    //                     stroke-linejoin="round"
    //                   />
    //                 </svg>
    //               </Link>
    //             </div>
    //           </div>
    //           <img
    //             src="https://i.ibb.co/Ms4qyXp/img-3.png"
    //             alt="sitting place"
    //             className="w-full mt-8 md:mt-6 hidden sm:block"
    //           />
    //           <img
    //             className="w-full mt-4 sm:hidden"
    //             src="https://i.ibb.co/6XYbN7f/Rectangle-29.png"
    //             alt="sitting place"
    //           />
    //         </div>
    //       </div>
    //       <div className="lg:w-1/2 xl:ml-8 lg:ml-4 lg:mt-0 md:mt-6 mt-4 lg:flex flex-col justify-between">
    //         <div className="relative">
    //           <div>
    //             <p className="md:p-10 p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">
    //               12 April 2021
    //             </p>
    //             <div className="absolute bottom-0 left-0 md:p-10 p-6">
    //               <h2 className="text-xl font-semibold 5 text-white">
    //                 The Decorated Ways
    //               </h2>
    //               <p className="text-base leading-4 text-white mt-2">
    //                 Dive into minimalism
    //               </p>
    //               <Link
    //                 to="/blog/4"
    //                 className="focus:outline-none focus:underline flex items-center mt-4 cursor-pointer text-white hover:text-gray-200 hover:underline"
    //               >
    //                 <p className="pr-2 text-sm font-medium leading-none">
    //                   Read More
    //                 </p>
    //                 <svg
    //                   className="fill-stroke"
    //                   width="16"
    //                   height="16"
    //                   viewBox="0 0 16 16"
    //                   fill="none"
    //                   xmlns="http://www.w3.org/2000/svg"
    //                 >
    //                   <path
    //                     d="M5.75 12.5L10.25 8L5.75 3.5"
    //                     stroke="currentColor"
    //                     stroke-width="2"
    //                     stroke-linecap="round"
    //                     stroke-linejoin="round"
    //                   />
    //                 </svg>
    //               </Link>
    //             </div>
    //           </div>
    //           <img
    //             src={Group}
    //             alt="sitting place"
    //             className="w-full sm:block hidden"
    //           />
    //           <img
    //             className="w-full sm:hidden"
    //             src="https://i.ibb.co/dpXStJk/Rectangle-29.png"
    //             alt="sitting place"
    //           />
    //         </div>
    //         <div className="sm:flex items-center justify-between xl:gap-x-8 gap-x-6 md:mt-6 mt-4">
    //           <div className="relative w-full">
    //             <div>
    //               <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">
    //                 12 April 2021
    //               </p>
    //               <div className="absolute bottom-0 left-0 p-6">
    //                 <h2 className="text-xl font-semibold 5 text-white">
    //                   The Decorated Ways
    //                 </h2>
    //                 <p className="text-base leading-4 text-white mt-2">
    //                   Dive into minimalism
    //                 </p>
    //                 <Link
    //                   to="/blog/5"
    //                   className="focus:outline-none focus:underline flex items-center mt-4 cursor-pointer text-white hover:text-gray-200 hover:underline"
    //                 >
    //                   <p className="pr-2 text-sm font-medium leading-none">
    //                     Read More
    //                   </p>
    //                   <svg
    //                     className="fill-stroke"
    //                     width="16"
    //                     height="16"
    //                     viewBox="0 0 16 16"
    //                     fill="none"
    //                     xmlns="http://www.w3.org/2000/svg"
    //                   >
    //                     <path
    //                       d="M5.75 12.5L10.25 8L5.75 3.5"
    //                       stroke="currentColor"
    //                       stroke-width="2"
    //                       stroke-linecap="round"
    //                       stroke-linejoin="round"
    //                     />
    //                   </svg>
    //                 </Link>
    //               </div>
    //             </div>
    //             <img
    //               src="https://i.ibb.co/3yvZBpm/img-4.png"
    //               className="w-full"
    //               alt="sitting place"
    //             />
    //           </div>
    //           <div className="relative w-full sm:mt-0 mt-4">
    //             <div>
    //               <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">
    //                 12 April 2021
    //               </p>
    //               <div className="absolute bottom-0 left-0 p-6">
    //                 <h2 className="text-xl font-semibold 5 text-white">
    //                   The Decorated Ways
    //                 </h2>
    //                 <p className="text-base leading-4 text-white mt-2">
    //                   Dive into minimalism
    //                 </p>
    //                 <Link
    //                   to="/blog/6"
    //                   className="focus:outline-none focus:underline flex items-center mt-4 cursor-pointer text-white hover:text-gray-200 hover:underline"
    //                 >
    //                   <p className="pr-2 text-sm font-medium leading-none">
    //                     Read More
    //                   </p>
    //                   <svg
    //                     className="fill-stroke"
    //                     width="16"
    //                     height="16"
    //                     viewBox="0 0 16 16"
    //                     fill="none"
    //                     xmlns="http://www.w3.org/2000/svg"
    //                   >
    //                     <path
    //                       d="M5.75 12.5L10.25 8L5.75 3.5"
    //                       stroke="currentColor"
    //                       stroke-width="2"
    //                       stroke-linecap="round"
    //                       stroke-linejoin="round"
    //                     />
    //                   </svg>
    //                 </Link>
    //               </div>
    //             </div>
    //             <img
    //               src="https://i.ibb.co/gDdnJb5/img-5.png"
    //               className="w-full"
    //               alt="sitting place"
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div>
  <div
    role="main"
    className="flex flex-col items-center justify-center mt-32 xs:mt-24 md:mt-32 lg:mt-48"
  >
    <h1 className="text-2xl sm:text-3xl md:text-4xl font-semibold leading-9 text-center text-black">
      Kurslarımız
    </h1>
    <p className="text-sm sm:text-md md:text-lg font-semibold leading-normal text-center text-black mt-4 lg:w-1/2 md:w-10/12 w-11/12">
      Ətraflı düyməsinə basaraq daha geniş məlumat əldə edə bilərsiniz.
    </p>
  </div>
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 sm:gap-8 md:gap-10 lg:gap-12 my-12 sm:my-16 md:my-16 lg:my-16 container mx-auto px-4">
    <div className="card relative w-full h-60 sm:h-72 md:h-80 lg:h-80 rounded-3xl cursor-pointer overflow-hidden">
      <img
        src={Group3}
        alt=""
        className="w-full h-full rounded-3xl object-cover object-center transition-transform duration-300 ease-in-out"
      />
      <div className="content absolute bottom-0 left-0 right-0 z-10 text-white p-4 pb-8 opacity-0 invisible transition-all duration-300 ease-in-out">
        <h4 className="text-lg sm:text-xl md:text-2xl m-0">Dizayn</h4>
        <span className="text-xs sm:text-sm font-medium">Əl işləri</span>
      </div>
      <div className="cover absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-[#201f20e6] to-transparent rounded-b-3xl opacity-0 invisible transition-all duration-300 ease-in-out"></div>
      <div className="shadow absolute bottom-0 left-1/2 right-1/2 transform -translate-x-1/2 w-64 sm:w-72 md:w-80 lg:w-72 h-20 sm:h-24 md:h-24 lg:h-24 bg-[#352720] blur-lg rounded-full opacity-0 invisible transition-all duration-500 ease-in-out"></div>
    </div>
        <div className="card relative w-full h-60 sm:h-72 md:h-80 lg:h-80 rounded-3xl cursor-pointer overflow-hidden">
          <img
            src={Group}
            alt=""
            className="w-full h-full rounded-3xl object-cover object-center transition-transform duration-300 ease-in-out"
          />
          <div className="content absolute bottom-0 left-0 right-0 z-10 text-white p-4 pb-8 opacity-0 invisible transition-all duration-300 ease-in-out">
            <h4 className="text-2xl m-0">
              {" "}
              <h4 className="text-2xl m-0">Dizayn</h4>
            </h4>
            <span className="text-sm font-medium">Əl işləri</span>
          </div>
          <div className="cover absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-[#201f20e6] to-transparent rounded-b-3xl opacity-0 invisible transition-all duration-300 ease-in-out"></div>
          <div className="shadow absolute bottom-0 left-1/2 right-1/2 transform -translate-x-1/2 w-72 h-24 bg-[#352720] blur-lg rounded-full opacity-0 invisible transition-all duration-500 ease-in-out"></div>
        </div>
        <div className="card relative w-full h-60 sm:h-72 md:h-80 lg:h-80 rounded-3xl cursor-pointer overflow-hidden">
          <img
            src={Group1}
            alt=""
            className="w-full h-full rounded-3xl object-cover object-center transition-transform duration-300 ease-in-out"
          />
          <div className="content absolute bottom-0 left-0 right-0 z-10 text-white p-4 pb-8 opacity-0 invisible transition-all duration-300 ease-in-out">
            <h4 className="text-2xl m-0">
              {" "}
              <h4 className="text-2xl m-0">Dizayn</h4>
            </h4>
            <span className="text-sm font-medium">Əl işləri</span>
          </div>
          <div className="cover absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-[#201f20e6] to-transparent rounded-b-3xl opacity-0 invisible transition-all duration-300 ease-in-out"></div>
          <div className="shadow absolute bottom-0 left-1/2 right-1/2 transform -translate-x-1/2 w-72 h-24 bg-[#352720] blur-lg rounded-full opacity-0 invisible transition-all duration-500 ease-in-out"></div>
        </div>
        <div className="card relative w-full h-60 sm:h-72 md:h-80 lg:h-80 rounded-3xl cursor-pointer overflow-hidden">
          <img
            src={Group2}
            alt=""
            className="w-full h-full rounded-3xl object-cover object-center transition-transform duration-300 ease-in-out"
          />
          <div className="content absolute bottom-0 left-0 right-0 z-10 text-white p-4 pb-8 opacity-0 invisible transition-all duration-300 ease-in-out">
            <h4 className="text-2xl m-0">Dizayn</h4>
            <span className="text-sm font-medium">Əl işləri</span>
          </div>
          <div className="cover absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-[#201f20e6] to-transparent rounded-b-3xl opacity-0 invisible transition-all duration-300 ease-in-out"></div>
          <div className="shadow absolute bottom-0 left-1/2 right-1/2 transform -translate-x-1/2 w-72 h-24 bg-[#352720] blur-lg rounded-full opacity-0 invisible transition-all duration-500 ease-in-out"></div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
